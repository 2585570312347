<template>
  <div>
    <Modal></Modal>
    <Banner></Banner>
    <b-container fluid="xl">
      <EcellectGoods v-if="showObj[1]"></EcellectGoods>
      <HotGoods v-if="showObj[2]"></HotGoods>
      <NewsCenter v-if="showObj[3] || showObj[4]"></NewsCenter>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getHomeData } from '@/api/api'
import Banner from './components/Banner'
import EcellectGoods from './components/EcellectGoods'
import HotGoods from './components/HotGoods'
import NewsCenter from './components/NewsCenter'
import Modal from './components/Modal'
export default {
  components: {
    Banner,
    EcellectGoods,
    HotGoods,
    NewsCenter,
    Modal
  },
  computed: {
    ...mapState(['siteId']),
    ...mapGetters(['cateShowObj']),
    showObj () {
      try {
        let obj = {}
        let showkey = Number(this.siteId) - 1
        for (let key in this.cateShowObj) {
          obj[key] = Number(this.cateShowObj[key][showkey])
        }
        return obj
      } catch {
        return {}
      }

    },
  },
  async mounted () {
    getHomeData()
  }
}
</script>